<template>
  <div ref="wrap" class="wrap wrapped-with-duqepuprbp">
    <CModal
      fullscreen
      backdrop="static"
      :visible="visible"
      :class="{ tallContent, show: visible }"
      @close="emit('close')"
    >
      <CModalHeader>
        <CModalTitle>{{ $t("part_checker") }}</CModalTitle>
      </CModalHeader>
      <CModalBody class="p-0">
        <CRow
          class="part-image__wrapper m-0"
          :class="{ onlyTwoColumns, outerColsHaveWrapped }"
        >
          <CCol class="p-0 images" :class="{ colsShouldWrap, colsHaveWrapped }">
            <CCol
              v-if="batch.part?.type === partType.SHEET"
              class="twoD part-image__image-wrapper"
            >
              <div class="pe-4 ms-4">
                <h3 class="d-flex flex-1">2D</h3>
                <div class="h-100 d-flex align-items-center justify-content-center">
                  <span
                    v-if="image2D"
                    :ref="el => cols.twoD.span ??= el as any"
                    class="part-image__image"
                    v-html="image2D"
                  />
                  <CSpinner v-else class="my-4" />
                </div>
              </div>
            </CCol>
            <CCol class="threeD part-image__image-wrapper">
              <div>
                <h3 class="d-flex flex-1">3D</h3>
                <div class="h-100 d-flex align-items-center justify-content-center">
                  <span
                    v-if="image3D"
                    :ref="el => cols.threeD.span ??= el as any"
                    class="part-image__image"
                    v-html="image3D"
                  />
                  <CSpinner v-else class="my-4" />
                </div>
              </div>
            </CCol>
          </CCol>
          <CCol class="model part-image__viewer-wrapper">
            <div class="w-100 h-100 d-flex justify-content-center align-items-center">
              <ModelViewer
                v-if="props.batch.part?.tree || model3D"
                :part="props.batch.part"
              />
              <CSpinner v-else />
            </div>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter class="justify-content-between">
        <CButton color="secondary" @click="emit('close')">
          {{ $t("close") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script lang="ts" setup>
import { watch, ref, onMounted, computed } from "vue-demi"
import { useWindowSize, watchThrottled } from "@vueuse/core"
import type { Batch } from "@/interfaces"
import { partStore } from "@/store"
import ModelViewer from "@/components/part-image/ModelViewer.vue"
import { sleep } from "@/libraries/helpers"
import { partType } from "@/interfaces"

const emit = defineEmits(["close"])

const props = defineProps<{ batch: Batch }>()

const visible = ref(false)

type Cols = {
  twoD: { span: HTMLSpanElement; g: SVGGElement; svgScale: number }
  threeD: { span: HTMLSpanElement; g: SVGGElement; svgScale: number }
}
const cols = ref<Cols>({
  twoD: { span: null, g: null, svgScale: 1 },
  threeD: { span: null, g: null, svgScale: 1 },
})

const { width, height } = useWindowSize()
const threeDCol = ref<HTMLDivElement>()
const modelCol = ref<HTMLDivElement>()
const colsShouldWrap = ref(false)
const colsHaveWrapped = ref(false)
const outerColsHaveWrapped = ref(false)
const tallContent = computed(
  () => !colsShouldWrap.value && colsHaveWrapped.value && outerColsHaveWrapped.value
)

const image3D = computed(() => partStore.all3DImages[props.batch?.part?.id])
const model3D = computed(() => partStore.all3DModels[props.batch?.part?.id])
const image2D = computed(() => partStore.all2DImages[props.batch?.part?.id])

const onlyTwoColumns = computed(
  () => props.batch.part?.source.extension === "dxf" || props.batch.part?.type !== partType.SHEET
)

const elHasSize = (el: HTMLElement | SVGElement) => {
  const { width, height } = el.getBoundingClientRect()
  return ![width, height].includes(0)
}

const elAspectRatio = (el: HTMLElement | SVGElement) => {
  const { width, height } = el.getBoundingClientRect()
  return width / height
}

const stopDecidingIfColsShouldWrap = watch(
  cols,
  cols => {
    if (onlyTwoColumns.value) return stopDecidingIfColsShouldWrap()

    const { twoD, threeD } = cols
    if (!twoD.g || !threeD.g) return

    if (!elHasSize(twoD.g)) return
    if (!elHasSize(threeD.g)) return

    const ratios = [elAspectRatio(twoD.g), elAspectRatio(threeD.g)].sort()

    colsShouldWrap.value = ratios[0] >= 0.9 && ratios[1] >= 1
    stopDecidingIfColsShouldWrap()
  },
  { deep: true }
)

const d = ["width", "height"] as const
const s = [
  ["Left", "Right"],
  ["Top", "Bottom"],
] as const

const scaleSvg = (col: Cols["twoD"]) => {
  col.g ??= col.span?.querySelector("svg > g")
  if (!col.g) return

  colsHaveWrapped.value = threeDCol.value?.offsetTop > 0
  outerColsHaveWrapped.value = modelCol.value?.offsetTop > 0

  const spanRect = col.span.getBoundingClientRect()
  const gRect = col.g.getBoundingClientRect()

  if ([gRect.width, gRect.height].includes(0)) return

  const gLength = (i: 0 | 1 = 0) => gRect[d[i]]
  const spanLength = (i: 0 | 1 = 0) => spanRect[d[i]]

  const spanStyle = getComputedStyle(col.span)
  const spanPadding = (i: 0 | 1 = 0) =>
    parseInt(spanStyle[`padding${s[i][0]}`]) + parseInt(spanStyle[`padding${s[i][1]}`])

  col.svgScale *=
    Math.round(
      Math.min(
        (spanLength(0) - spanPadding(0)) / gLength(0),
        (spanLength(1) - spanPadding(1)) / gLength(1)
      ) * 10000
    ) / 10000
}

watchThrottled(
  [cols, width, height],
  ([{ twoD, threeD }]) => {
    scaleSvg(twoD)
    scaleSvg(threeD)
  },
  { deep: true, throttle: 20 }
)

watch(
  () => props.batch.part,
  () => {
    if (props.batch.part && !props.batch.part.filename?.endsWith(".dxf")) 
      partStore.get3DModelsByIds([props.batch.part.id])
  }
)

onMounted(async () => {
  // Wait for svg to be scaled before showing
  await sleep(100)
  visible.value = true
  await sleep(500)
  threeDCol.value = document.querySelector(".col.threeD")
  modelCol.value = document.querySelector(".col.model")
  if (props.batch.part && !props.batch.part.filename?.endsWith(".dxf")) partStore.get3DModelsByIds([props.batch.part.id])
})
</script>

<style lang="scss">
.wrapped-with-duqepuprbp {
  .part-image {
    &__image {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 2rem;
      align-items: center;
      svg {
        width: 400px;
        height: 400px;
      }
      h3 {
        display: flex;
        width: 100%;
      }
    }
    &__image-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0px;
    }
    &__viewer {
      svg {
        // filter: invert(100%);
        width: 450px;
        height: 450px;
      }
      &-wrapper {
        padding: 0px;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.2);
        // background-color: rgba(0,0,0,0.8);
        flex-direction: column;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.wrap {
  display: contents;
}
h3 {
  position: absolute;
  margin: 0.5em;
}
.wrap:deep(.modal) {
  &.tallContent > .modal-fullscreen {
    min-height: calc(1300px + 8rem);
  }
  & > .modal-fullscreen {
    margin: auto;
    padding: 4em;
    width: 100%;
    min-height: calc(800px + 8rem);

    .modal-body {
      overflow-y: hidden;

      & > .row {
        flex-wrap: wrap;
        height: 100%;

        & > .images {
          display: flex;
          flex-wrap: wrap;

          & > .col > div {
            width: 100%;
            height: 100%;
            position: relative;
          }
        }
      }

      & .model .container {
        max-width: 100%;
      }

      & > .row:not(.outerColsHaveWrapped) {
        & > .col.images {
          display: flex;
          flex: 1 1 67%;
          height: 100%;

          & > .col {
            display: flex;

            & > div {
              width: 100%;
              height: 100%;
              position: relative;
            }
          }

          & + .model {
            display: flex;
            flex: 1 1 33%;
            min-width: 350px;
          }
        }

        & > .col.images:not(.colsShouldWrap) {
          & > .col {
            flex: 1 1 50%;
          }
        }

        & > .col.images.colsShouldWrap {
          flex-wrap: wrap;
          flex: 1 1 67%;
          & > .col {
            flex: 1 1 500px;
          }

          & + .model {
            flex: 1 1 33%;
          }

          &.colsHaveWrapped {
            &,
            & + .model {
              flex: 1 1 530px;
            }

            & > .col {
              flex: 1 1 400px;
            }
          }
        }
      }

      & > .row.outerColsHaveWrapped {
        &.onlyTwoColumns > .col.images {
          &,
          & + .model {
            min-width: auto;
            flex: 1 1 500px !important;
          }
        }
        & > .col.images:not(.colsHaveWrapped) {
          &,
          & + .model {
            height: 50%;
            flex: 1 1 600px;
          }

          & > .col {
            flex: 1 1 50%;
            height: 100%;
            min-width: 320px;
          }

          // &.colsShouldWrap {
          //   & + .model {
          //     min-width: 0;
          //     flex: 0 1 0;
          //   }
          // }
        }

        & > .col.images.colsHaveWrapped {
          width: 100%;
          height: 67%;

          &:not(.colsShouldWrap) {
            min-height: 800px;
            & > .col,
            & + .model {
              min-height: 400px;
            }
          }

          &,
          & + .model {
            flex: 1 1 500px;
          }

          & + .model {
            height: 33%;
          }

          & > .col {
            height: auto;
            width: 100%;
            flex: 1 1 320px;
          }
        }
      }

      & > .row.part-image__wrapper.onlyTwoColumns > .col {
        max-height: 100%;
        flex: 1 1 50% !important;
        min-width: 450px;

        .col.threeD,
        .col.threeD > div {
          height: 100%;
        }

        .part-image__wrapper > .col {
          display: flex;
        }

        .part-image__image,
        svg {
          width: 100%;
          height: 100%;
        }

        .threeD svg {
          width: 100% !important;
          transform: none !important;
        }

        &:first-child .align-items-center {
          align-items: unset !important;
        }
      }

      .part-image__image {
        width: 100%;
        height: 100%;
        position: absolute;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .twoD svg {
        transform: scale(v-bind("cols.twoD.svgScale"));
      }

      .threeD svg {
        transform: scale(v-bind("cols.threeD.svgScale"));
      }
    }
  }
}
</style>
