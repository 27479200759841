<template>
  <v-client-table
    :key="treeView"
    ref="tableElement"
    :columns="tableOptions.columns"
    :data="batches"
    :options="tableOptions.options"
    @select="selectedBatchIds = $event.map(b => b.id)"
    @pagination="$nextTick(calculateTotalVisibleChildren)"
    @filter="$nextTick(calculateTotalVisibleChildren)"
    @row-click="v => $emit('row-click', v)"
  >
    <template #index="{ row }">
      <div
        class="d-flex h-100 align-items-center review-batch-index"
      >
        <TableTreeExpander
          v-show="props.treeView"
          class="me-3"
          :is-expanded="!hiddenTreeviewIds.includes(row.treeview?.id)"
          :isChild="row.treeview?.level != 0"
          :deep="row.treeview?.level"
          :total-children="row.treeview?.childrenIds?.length || 0"
          :total-visible-children="totalVisibleChildren[row.treeview?.id || row.id]"
          @click.stop
          @expand="hiddenTreeviewIds = hiddenTreeviewIds.filter(id => id !== row.treeview?.id)"
          @collapse="hideBatchTreeviewId(row.treeview?.id)"
        />
        <span>{{ row.index }}</span>
      </div>
    </template>

    <template #preview="{ row }">
      <div
        v-if="!row.isDummy()"
        style="width: 50px; height: 50px"
      >
        <PartImage :batch="row" @click.stop="" />
      </div>
    </template>

    <template #name="{ row }">
      <b class="white-space__nowrap">{{ row.part?.name }}</b>
      <div
        v-if="row.part?.type == partType.SHEET && !row.isDummy()"
        class="small text-muted white-space__nowrap"
      >
        <span>
          {{ row.part?.width.toFixed(2) }}&times;
          {{ row.part?.height.toFixed(2) }}
        </span>
        <span v-if="row.part.length">
          &times; {{ row.part?.length.toFixed(2) }}
        </span>
      </div>
    </template>

    <template #stock="{ row }">
      <div v-if="row.stock && !row.isDummy()">
        <b>{{ row.stock?.name }}</b>
        <div class="small text-muted">
          <span v-if="row.part?.type == partType.SHEET">
            {{ row.stock?.thickness.toFixed(2) }} mm
          </span>
          <span v-if="row.part?.type == 'TUBE'">{{ row.stock?.hash }}</span>
        </div>
      </div>
    </template>

    <template #operations="{ row }">
      <template v-if="!row.isDummy()">
        <span v-for="(time, i) in row.times" :key="i">
          <CBadge color="primary">{{ time.machine?.name }}</CBadge>
        </span>
      </template>
    </template>

    <template #reference="{ row }">
      <template v-if="!row.isDummy()">
        {{ row.part?.reference }}
      </template>
    </template>

    <template #boolean__is_valid="{ row }">
      <i
        v-if="!row.isDummy()"
        :style="`color: ${row.is_valid === true ? 'green' : 'red'}`"
        :class="['fa', row.is_valid === true ? 'fa-check' : 'fa-times']"
      />
    </template>

    <template #quantity="{ row }">
      <span v-if="!row.isDummy()">{{ row.quantity }}</span>
    </template>

    <template #part_tree_quantity="{ row }">
      <span>{{ row.treeview?.quantity }}</span>
    </template>

    <template #price="{ row }">
      <span v-if="!row.isDummy()">
        {{
          toCurrency(
            !isNaN(row.unit_amount) && row.unit_amount != null
              ? row.unit_amount
              : 0
          )
        }}
      </span>
    </template>

    <template #total="{ row }">
      <span v-if="!row.isDummy()">
        {{
          toCurrency(
            !isNaN(row.total_amount) && row.total_amount != null
              ? row.total_amount
              : 0
          )
        }}
      </span>
    </template>

    <template #actions="{ row }">
      <div
        v-if="!row.isDummy()"
        class="button-groups"
      >
        <CButton
          v-if="row.part?.type === partType.SHEET"
          class="me-2"
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'DXF')"
        >
          <b>DXF</b>
        </CButton>

        <CButton
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'STP')"
        >
          <b>STP</b>
        </CButton>

        <CButton
          class="ms-2"
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'CYCAD')"
        >
          <b>CyCAD</b>
        </CButton>

        <!-- PDF only available for SHEET part -->
        <CButton
          v-if="row.part?.type === partType.SHEET"
          class="ms-2"
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'PDF', 'preview-pdf')"
        >
          <b>PDF</b>
        </CButton>

        <CButton
          class="ms-2"
          size="sm"
          color="warning"
          @click.stop="fileStore.download(getId(row.part?.source))"
        >
          <b>SOURCE</b>
        </CButton>
      </div>
    </template>
  </v-client-table>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, type Ref } from "vue-demi"
import { useI18n } from "vue-i18n"
import PartImage from "@/components/part-image/PartImage.vue"
import { batchStore, authStore, fileStore } from "@/store"
import { getFilterEmitsOptions, getListColumns, sleep, toCurrency } from "@/libraries/helpers"
import TableTreeExpander from '@/components/TableTreeExpander.vue'
import { treeHelpers, getBatchesSortStrings } from "../helpers"
import type { MappedInjectedBatchInterface } from '@/interfaces'
import { partType } from "@/interfaces"
import { getId } from '@/interfaces'

const i18n = useI18n()
const emit = defineEmits(["row-click"])

const props = withDefaults(
  defineProps<{
    orderId: number
    treeView?: boolean
  }>(),
  {
    treeView: false,
  }
)

const tableElement = ref<Ref>(null)

const hiddenTreeviewIds = ref([])
const selectedBatchIds = ref([])
const totalVisibleChildren = ref({})

const batches = computed(() => batchStore.getBatchesByOrderId(props.orderId, props.treeView))

const tableOptions = computed(() => {
  const batchesSortStrings = getBatchesSortStrings(batches.value, props.treeView)

  const columns = [
    ...[
      "index",
      "preview",
      "name",
      "stock",
      "operations",
      "boolean__is_valid",
      "reference"
    ],
    ...(props.treeView ? ["part_tree_quantity"] : []),
    ...[
      "quantity",
      "price",
      "total",
    ]
  ]
  if (authStore.authenticatedUser?.is_admin || authStore.authenticatedUser?.is_manufacturer) columns.push("actions")
  return {
    columns,
    options: {
      perPage: 100,
      texts: {
        count: "",
        filter: "",
        filterPlaceholder: "",
        limit: "",
        page: "",
        noResults: i18n.t("no_results"),
        loading: i18n.t("loading"),
      },
      skin: "table table-hover",
      rowAttributesCallback: row => ({ id: row.id, "treeview-id": row.treeview?.id || row.id }),
      headings: {
        index: "#",
        boolean__is_valid: i18n.t("valid"),
        name: i18n.t("name"),
        material: i18n.t("material"),
        operations: i18n.t("operations"),
        stock: i18n.t("stock"),
        quantity: i18n.t("total_quantity"),
        part_tree_quantity: i18n.t("original_quantity"),
        price: i18n.t("price"),
        total: i18n.t("total"),
        actions: "Downloads",
      },
      sortable: [
        "index",
        "boolean__is_valid",
        "name",
        "stock",
        "part_tree_quantity",
        "quantity",
        "reference",
        "operations",
        "price",
        "total",
      ],
      sortIcon: {
        base: "fa fa-lg",
        up: "fa-sort-asc",
        down: "fa-sort-desc",
        is: "fa-sort",
      },
      orderBy: {
        column: "index",
        ascending: false,
      },
      customSorting: {
        index: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText: string = (a.index || "").toString().padStart(5, "0")
            let bText: string = (b.index || "").toString().padStart(5, "0")
            if (props.treeView) {
              aText = `${batchesSortStrings[a.treeview?.id || a.id]?.index || ""}${!ascending ? "~" : ""}`
              bText = `${batchesSortStrings[b.treeview?.id || b.id]?.index || ""}${!ascending ? "~" : ""}`
            }
            if (ascending) return aText >= bText ? 1 : -1

            return aText <= bText ? 1 : -1
          }
        },
        boolean__is_valid: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText: string | number = +a.is_valid
            let bText: string | number = +b.is_valid
            if (props.treeView) {
              aText = batchesSortStrings[a.treeview?.id || a.id]?.[`is_valid_${ascending ? "ascending" : "descending"}`] || ""
              bText = batchesSortStrings[b.treeview?.id || b.id]?.[`is_valid_${ascending ? "ascending" : "descending"}`] || ""
            }
            if (ascending) return aText >= bText ? 1 : -1

            return bText >= aText ? 1 : -1
          }
        },
        name: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = a.part.name
            let bText = b.part.name
            if (props.treeView) {
              aText = `${batchesSortStrings[a.treeview?.id || a.id]?.name || ""}${!ascending ? "~" : ""}`
              bText = `${batchesSortStrings[b.treeview?.id || b.id]?.name || ""}${!ascending ? "~" : ""}`
            }

            if (ascending) return aText >= bText ? 1 : -1

            return aText <= bText ? 1 : -1
          }
        },
        stock: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = a.stock?.name || ""
            let bText = b.stock?.name || ""
            if (props.treeView) {
              aText = batchesSortStrings[a.treeview?.id || a.id]?.[`stock_${ascending ? "ascending" : "descending"}`] || ""
              bText = batchesSortStrings[b.treeview?.id || b.id]?.[`stock_${ascending ? "ascending" : "descending"}`] || ""
            }

            if (ascending) return aText >= bText ? 1 : -1

            return bText >= aText ? 1 : -1
          }
        },
        quantity: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = (a.quantity || "").toString().padStart(5, "0")
            let bText = (b.quantity || "").toString().padStart(5, "0")
            if (props.treeView) {
              aText = `${batchesSortStrings[a.treeview?.id || a.id]?.quantity || ""}${!ascending ? "~" : ""}`
              bText = `${batchesSortStrings[b.treeview?.id || b.id]?.quantity || ""}${!ascending ? "~" : ""}`
            }
            if (ascending) return aText >= bText ? 1 : -1

            return aText <= bText ? 1 : -1
          }
        },
        part_tree_quantity: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = (a.treeview?.quantity || "").toString().padStart(5, "0")
            let bText = (b.treeview?.quantity || "").toString().padStart(5, "0")
            if (props.treeView) {
              aText = `${batchesSortStrings[a.treeview?.id || a.id]?.part_tree_quantity || ""}${!ascending ? "~" : ""}`
              bText = `${batchesSortStrings[b.treeview?.id || b.id]?.part_tree_quantity || ""}${!ascending ? "~" : ""}`
            }

            if (ascending) return aText >= bText ? 1 : -1
            return aText <= bText ? 1 : -1
          }
        },
        reference: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = a.part?.reference || ""
            let bText = b.part?.reference || ""
            if (props.treeView) {
              aText = batchesSortStrings[a.treeview?.id || a.id]?.[`reference_${ascending ? "ascending" : "descending"}`] || ""
              bText = batchesSortStrings[b.treeview?.id || b.id]?.[`reference_${ascending ? "ascending" : "descending"}`] || ""
            }
            if (ascending) return aText >= bText ? 1 : -1

            return bText >= aText ? 1 : -1
          }
        },
        operations: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = (a.times?.length || "").toString().padStart(5, "0")
            let bText = (b.times?.length || "").toString().padStart(5, "0")
            if (props.treeView) {
              aText = `${batchesSortStrings[a.treeview?.id || a.id]?.times || ""}${!ascending ? "~" : ""}`
              bText = `${batchesSortStrings[b.treeview?.id || b.id]?.times || ""}${!ascending ? "~" : ""}`
            }
            if (ascending) return aText >= bText ? 1 : -1

            return aText <= bText ? 1 : -1
          }
        },
        price: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = (a.unit_amount || "").toString().padStart(10, "0")
            let bText = (b.unit_amount || "").toString().padStart(10, "0")
            if (props.treeView) {
              aText = `${batchesSortStrings[a.treeview?.id || a.id]?.unit_amount || ""}${!ascending ? "~" : ""}`
              bText = `${batchesSortStrings[b.treeview?.id || b.id]?.unit_amount || ""}${!ascending ? "~" : ""}`
            }
            if (ascending) return aText >= bText ? 1 : -1

            return aText <= bText ? 1 : -1
          }
        },
        total: (ascending: boolean) => {
          return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
            let aText = (a.total_amount || "").toString().padStart(10, "0")
            let bText = (b.total_amount || "").toString().padStart(10, "0")
            if (props.treeView) {
              aText = `${batchesSortStrings[a.treeview?.id || a.id]?.total_amount || ""}${!ascending ? "~" : ""}`
              bText = `${batchesSortStrings[b.treeview?.id || b.id]?.total_amount || ""}${!ascending ? "~" : ""}`
            }
            if (ascending) return aText >= bText ? 1 : -1

            return aText <= bText ? 1 : -1
          }
        },
      },
      filterByColumn: true,
      get filterable() {
        return [
          ...[
            "index",
            "name",
            "stock",
            "quantity",
            "reference",
            "operations",
            "price",
            "total",
            "boolean__is_valid",
          ],
          ...(props.treeView ? ["part_tree_quantity"] : []),
        ]
      },
      get filterAlgorithm() {
            return {
              index: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.index || "").toString().toLowerCase()
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  const childrenIndexes = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => batch.index)
                  str += `###${childrenIndexes.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              name: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.part?.name || "").toLowerCase()
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => (batch.part?.name || "").toLowerCase())
                  str += `###${children.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              stock: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.stock?.name || "").toLowerCase()
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => (batch.stock?.name || "").toLowerCase())
                  str += `###${children.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              quantity: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.quantity || 0).toString().toLowerCase()
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => (batch.quantity || 0).toString().toLowerCase())
                  str += `###${children.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              part_tree_quantity: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.treeview?.quantity || 0).toString().toLowerCase()
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => (batch.treeview?.quantity || 0).toString().toLowerCase())
                  str += `###${children.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              operations: (row: MappedInjectedBatchInterface, query: string) => {
                let str = row.times?.map(o => o.machine?.name || "").join("###") || ""
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => batch.times?.map(o => o.machine?.name || "").join("###") || "")
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
              reference: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.part?.reference || "").toLowerCase()
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => batch.part?.reference || "")
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
              price: (row: MappedInjectedBatchInterface, query: string) => {
                // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                let str = toCurrency(isNaN(row.unit_amount) || row.unit_amount === 0 ? 0 : row.unit_amount)
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => toCurrency(isNaN(batch.unit_amount) || batch.unit_amount === 0 ? 0 : batch.unit_amount))
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
              total: (row: MappedInjectedBatchInterface, query: string) => {
                // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                let str = toCurrency(isNaN(row.total_amount) || row.total_amount === 0 ? 0 : row.total_amount)
                if (props.treeView && row.treeview?.childrenIds.length > 0) {
                  // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                  const children = batches.value.filter(batch => row.treeview?.childrenIds.includes(batch.treeview?.id || "")).map(batch => toCurrency(isNaN(batch.total_amount) || batch.total_amount === 0 ? 0 : batch.total_amount))
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
              boolean__is_valid(row: MappedInjectedBatchInterface, query: string) {
                let values: (boolean | string)[] = [row.is_valid]
                if (props.treeView) {
                  const children = batches.value
                    .filter(batch => (row.treeview?.childrenIds || []).includes(batch.treeview?.id || ""))
                    .map(batch => batch.is_valid)
                  values = [...values, ...children]
                }
                return values.join("###").toLowerCase().includes(query.toLowerCase())
              }
            }
          },
          get listColumns() {
            return getListColumns(this.filterable)
      },
      clientMultiSorting: false
    },
  }
})

const calculateTotalVisibleChildren = () => {
  totalVisibleChildren.value = treeHelpers.calculateTotalVisibleChildren(batches.value, totalVisibleChildren.value)
}

const hideBatchTreeviewId = (id: number) => {
  hiddenTreeviewIds.value = treeHelpers.hideBatchTreeviewId(id, batches.value, hiddenTreeviewIds.value)
}

watch(batches,
  async () => {
    treeHelpers.handleBatchTree(batches.value, hiddenTreeviewIds.value)
    await sleep(100)
    calculateTotalVisibleChildren()
  },
  { deep: true }
)
watch(hiddenTreeviewIds,
  async () => {
    treeHelpers.handleBatchTree(batches.value, hiddenTreeviewIds.value)
    await sleep(100)
    calculateTotalVisibleChildren()
  },
  { deep: true }
)
watch(() => props.treeView,
  () => {
    hiddenTreeviewIds.value = []
  }
)

onMounted(() => {
  if (tableElement.value) {
    (tableElement.value as any).$.emitsOptions = {
      ...(tableElement.value as any).$.emitsOptions,
      ...getFilterEmitsOptions(tableOptions.value.options.filterable),
    }
  }
})
</script>

<style lang="scss">
.review-batch-index {
  .table-tree-expander {
    &__expanded::before {
      top: 1rem;
      height: calc(100% + 3rem + (var(--total-children) - 1) * 4.645rem);
    }
    &__children {
      &::after {
        width: 2rem;
      }
    }
  }
}
.VueTables__row {
  height: 65px;
}
</style>
