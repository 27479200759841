<template>
  <div class="wrapped-with-d995a15e4f flex-container animated fadeIn">
    <ErrorCard
      v-if="errors.show"
      :show="errors.show"
      :message="errors.message"
      @update:model-value="v => errors.show = v"
    />

    <CCard class="batch-list-card">
      <CCardBody>
        <CRow>
          <CCol :sm="2">
            <CCardTitle>
              {{ !!orderDetail ? $t("order_no") : $t("start_order") }}
            </CCardTitle>

            <div
              v-if="orderDetail && !isEmpty(orderDetail)"
              class="text-muted small"
              style="cursor: pointer"
              @click.stop="copyTextToClipboard((orderDetail?.id || '').toString(), `${orderDetail.id} ${$t('copied_to_clipboard')}`)"
            >
              <i class="fa fa-copy" />
              {{ orderDetail.id }}
            </div>
          </CCol>
          <CCol :sm="10" class="d-flex justify-content-end align-items-center">
            <template v-if="authStore.authenticatedUser?.is_manufacturer">
              <span class="me-2">{{ $t("create_order_for") }}:&nbsp;</span>
              <CButton
                v-if="!selectedCustomer"
                color="primary"
                size="sm"
                @click="() => (showModals.customerPicker = true)"
              >
                {{
                  authStore.authenticatedUser?.is_customer && !authStore.authenticatedUser?.is_manufacturer
                    ? $t("select_manufacturer")
                    : $t("select_customer")
                }}
              </CButton>
              <div
                v-if="checkOrderFor && authStore.authenticatedUser?.is_manufacturer"
                class="text-primary"
                style="cursor: pointer"
                @click="() => (showModals.customerPicker = true)"
              >
                {{ selectedCustomer?.name }}
              </div>
              <div v-else class="fw-bold">
                {{ selectedCustomer?.name }}
              </div>
            </template>
          </CCol>
        </CRow>
        <hr class="bigHr" />
        <CRow class="mb-4">
          <CCol :sm="2">
            <ButtonSwitch
              :value="treeView"
              :items="[
                { value: false, text: $t('list_view') },
                { value: true, text: $t('tree_view') }
              ]"
              @update:model-value="v => treeView = v"
            />
          </CCol>
          <CCol :sm="2">
            <CFormInput
              v-model="orderReference"
              :placeholder="`${$t('reference')}`"
              name="reference-input"
            />
          </CCol>
          <CCol :sm="2">
            <CFormInput
              v-model="orderDescription"
              :placeholder="$t('description')" 
              name="description-input"
            />
          </CCol>
          <CCol :sm="{ span: 2, offset: 1 }">
            <CInputGroup>
              <CInputGroupText class="btn btn-secondary">
                <i class="fa fa-search" />
              </CInputGroupText>
              <CFormInput
                v-model="globalSearch"
                :placeholder="$t('filter')"
                name="filter-input"
              />
            </CInputGroup>
          </CCol>
          <CCol :sm="2">
            <CButton color="primary" @click="showModals.visibleColumn = true">
              <i class="fa fa-eye" />
              {{ $t("show_or_hide_columns") }}
            </CButton>
          </CCol>
          <CCol :sm="1">
            <MergerModal
              :title="`${(orderDetail?.id || 'order').toString()}-batches`"
              :data="batches"
              :set-row="setRow"
              :show="showModals.merger"
              :schema="mergerOptions.schema"
              :data-processor="mergerOptions.dataProcessor"
              :value-processor="mergerOptions.valueProcessor"
              :custom-validation="mergerOptions.customValidation"
              :allow-new-row="false"
              :request-batch="1"
              :disable-merger="isProcessingFile"
              @close="showModals.merger = false"
              @open="showModals.merger = true"
            >
              {{ $t("merge-component-title") }}
            </MergerModal>
          </CCol>
        </CRow>

        <div
          class="new-order-table"
          :class="{
            'no-data': batches.length === 0,
            'row-selected': /*!!batchesSelected.length,*/false
          }"
        >
          <EditBatchTable
            :key="tableKey"
            :order-id="orderId"
            :columns="columns"
            :loading="loading"
            :update-machines="updateMachines"
            :update-stock="updateStock"
            :tree-view="treeView"
            :global-search="globalSearch"
            :auto-select-option="autoSelectOption"
            :files="uploadedFiles"
            @remove-failed-file="file => uploadedFiles = uploadedFiles.filter(f => f.originalFile !== file.originalFile)"
            @process-duplicate="processDuplicateFile"
          />
        </div>
      </CCardBody>
    </CCard>

    <!-- Dropzone, subtotal and submit -->
    <CRow class="mt-4">
      <CCol
        :key="(batches?.length == 0).toString()"
        :sm="batches?.length > 0 ? 8 : 12"
        class="position-relative"
      >
        <div
          v-if="!selectedCustomer"
          class="dropzone-ghost cursor-pointer d-flex justify-content-center align-items-center"
          @click="
            () => {
              selectFileAfterPick = true
              showModals.customerPicker = true
            }
          "
        >
          <span>
            {{
              authStore.authenticatedUser?.is_customer && !authStore.authenticatedUser?.is_manufacturer
                ? $t("select_manufacturer_to_upload_file")
                : $t("select_customer_to_upload_file")
            }}
          </span>
        </div>
        <Dropzone
          id="neworder-dropzone"
          :options="dropzoneOptions"
          @addedfile="onFileAdded"
          @success="uploadEnd"
          @initialized="e => ($neworderDropzone = e)"
          @error="onFileError"
        />
        <div class="text-muted small" style="margin: -2em 0 auto 1em">
          {{ $t("supported_files") }}
        </div>
      </CCol>
      <CCol v-if="orderDetail && batches?.length != 0" :sm="4" class="flex flex-column">
        <div class="d-inline-block subTotalBlock">
          <CRow>
            <CCol>
              <h4>{{ $t("total") }}:</h4>
            </CCol>
            <CCol class="d-flex justify-content-end">
              <div class="priceTotal">{{ orderDetail.formatted_total_amount.subtotal }}</div>
            </CCol>
          </CRow>
        </div>
        <CButton
          class="p-3 w-100"
          shape="rounded-0"
          size="lg"
          color="primary"
          :disabled="loading"
          @click="!loading ? router.push(`/orders/${orderId}`) : () => {}"
        >
          <span v-if="!loading">
            {{ $t("continue") }}
          </span>
          <CSpinner v-else />
        </CButton>
      </CCol>
    </CRow>
    <!-- End dropzone, subtotal and submit -->

    <!-- Modals -->
    <VisibleColumnModal
      v-model="columns"
      :options="BATCH_TABLE_COLUMNS"
      :show="showModals.visibleColumn"
      @show="v => (showModals.visibleColumn = v)"
    />
    <CustomerPickerModal
      v-if="!orderId && authStore.authenticatedUser?.is_manufacturer"
      v-model="showModals.customerPicker"
      @picked="createOrderFor"
    />
    <!-- End Modals -->
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, ref, watchEffect, nextTick, onUnmounted, type Ref, computed } from "vue-demi"
import { isEmpty, chunk, debounce, isEqual, intersection, pick, uniq, forEach, cloneDeep, last, pickBy } from "lodash-es"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import { useMeta } from "vue-meta"
import { v4 as uuidv4 } from "uuid"
import type { Customer, File, Part, Order, updateUploadedFileInterface, uploadedFileInterface, OptimisticUpdateOptionInterface, CreateBatch } from "@/interfaces"
import { uploadFileStatus } from "@/interfaces"
import { getId, mergerDefaultIdColumn, httpStatusCodes } from "@/interfaces"
import { batchStore, organizationStore, authStore, partStore, fileStore, orderStore, globalStore } from "@/store"
import { addOrUpdate, PartHelpers, copyTextToClipboard, errorHandler } from "@/libraries/helpers"
import { BATCH_TABLE_COLUMNS } from "@/constants"
import { getMergerOptions, generateMergerPayload } from "./helpers"
import Dropzone from "@/components/Dropzone.vue"
import CustomerPickerModal from "@/components/modals/customer/CustomerPickerModal.vue"
import MergerModal from "@/components/modals/MergerModal.vue"
import VisibleColumnModal from "@/components/modals/VisibleColumnModal.vue"
import ErrorCard from "./components/ErrorCard.vue"
import EditBatchTable from "./components/EditBatchTable.vue"
import ButtonSwitch from "./components/ButtonSwitch.vue"

const { document } = window

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

useMeta({ title: i18n.t("new_order") })

const dropzoneOptions = {
  addRemoveLinks: true,
  acceptedFiles: ".step,.stp,.dxf",
  dictDefaultMessage: i18n.t("upload_modal_body"),
}

/** Refs */
  const $neworderDropzone = ref<Ref>(null)

  const orderId = ref<number>(null)
  const showModals = ref({
    visibleColumn: false,
    customerPicker: false,
    merger: false
  })
  const errors = ref({
    show: false,
    message: ""
  })

  const fileCreatedOrder = ref(null)
  const fileIsCreatingOrder = ref(false)
  const uploadedFiles = ref([] as uploadedFileInterface[])

  const globalSearch = ref("")
  const loading = ref(false)
  const inputReference = ref("")
  const inputDescription = ref("")
  const isReady = ref(false)
  const refreshKey = ref(uuidv4()) // to refresh table based on some conditions set
  const selectFileAfterPick = ref(false)
  const selectedCustomer = ref<Customer>()
  const treeView = ref<boolean>(false)
/** End Refs */

/** Computed */
  const batches = computed(() => batchStore.getBatchesByOrderId(orderId.value))

  const checkOrderFor = computed(() => selectedCustomer.value && orderId.value == null)
  const checkValid = computed(() => batches.value.every(batch => !!batch.is_valid))
  const columns = computed({
    get: () => {
      const defaultColumns = cloneDeep(BATCH_TABLE_COLUMNS)
      if (treeView.value) {
        const index = defaultColumns.findIndex(c => c === "quantity")
        if (index !== -1) defaultColumns.splice(index, 0, "part_tree_quantity")
      }
      return localStorage.columns ? intersection(JSON.parse(localStorage.columns), defaultColumns) : defaultColumns
    },
    set: (v) => {
      localStorage.columns = JSON.stringify(v)
      refreshKey.value = uuidv4()
    }
  })
  const mergerOptions = computed(() => getMergerOptions(batches.value))

  const orderDetail = computed(() => orderStore.getOrderById(orderId.value))
  const orderReference = computed({
    get: () => orderDetail.value?.reference || inputReference.value,
    set: v => {
      inputReference.value = v
      if (orderDetail.value) updateOrderDetail()
    },
  })
  const orderDescription = computed({
    get: () => orderDetail.value?.description || inputDescription.value,
    set: v => {
      inputDescription.value = v
      if (orderDetail.value) updateOrderDetail()
    }
  })

  const tableKey = computed(() => `${refreshKey.value}_${checkValid.value}`) // refresh table based on this key

  const isProcessingFile = computed(() => uploadedFiles.value.some(f => [uploadFileStatus.UPLOADING, uploadFileStatus.PROCESSING].includes(f.status)))
/** End Computed */

/** Functions */
  async function autoSelectOption(batchIds: number[] = []) {
    const allBatches = cloneDeep(batchStore.all)
    const allChanges = []

    forEach(batchIds, (batch_id) => {
      const index = allBatches.findIndex(b => b.id === batch_id)
      if (index === -1) return

      const batch = batchStore.mappedData.find(b => b.id === batch_id)
      const { process, options } = batch

      let changes = []
      for (const key in options) {
        // if only one available option, auto-select it
        if (options[key].length === 1) {
          // force update stock if stock value is different from available option
          if (key === "materials" && allBatches[index].injected_material !== options[key][0].id) {
            allBatches[index].injected_material = options[key][0].id
            changes.push("stock")
          }
          if (key === "thicknesses" && allBatches[index].injected_thickness !== options[key][0].value) {
            allBatches[index].injected_thickness = options[key][0].value
            changes.push("stock")
          }

          if (key === "processes" && !process?.id) {
            allBatches[index].machines.push(options[key][0].id)
            changes.push("machine")
          }

          // if material and thickness selected from batch injectValues function
          if (
            !changes.includes("stock")
            && allBatches[index].injected_material
            && allBatches[index].injected_thickness
            && allBatches[index].needUpdate
          ) {
            changes.push("stock")
            allBatches[index].needUpdate = false
          }
        }
      }
      
      // update parent if expected parent and current parent is different
      if (
        batch.partBasedParentId !== allBatches[index].parent
        // && !batch.parent // only set tree if batch has no parent, leave it commented for now
      ) changes.push("parent")

      if (changes.length > 0) 
        allChanges.push({
          batch_id,
          index,
          changes
        })
    })

    if (allChanges.length > 0) batchStore.all = allBatches

    forEach(allChanges, async ({ batch_id, index, changes }) => {
      const mappedBatch = batchStore.mappedData.find(b => b.id === batch_id)
      const batch = batchStore.all[index]
      if (
        changes.includes("stock")
        && batch.injected_material
        && batch.injected_thickness
      )
        updateStock(mappedBatch.injected_material, batch_id)

      if (changes.includes("machine"))
        updateMachines(last(allBatches[index].machines), batch_id, { withoutOptimistic: true })

      // update batches parent based on batches part's parent
      if (changes.includes("parent")) {
        batchStore.update({ id: mappedBatch.id, parent: mappedBatch.partBasedParentId })
      }
    })

  }
  function createOrderFor(customer: Customer) {
    selectedCustomer.value = customer

    if (selectFileAfterPick.value) {
      document.getElementById("neworder-dropzone").click()
    }
    selectFileAfterPick.value = false
  }
  function fetchBatches(fetchPart = true, fetchFile = true) {
    if (!orderId.value) return
    loading.value = true
    const requests = [batchStore.fetchBatchesByOrderId(orderId.value)]
    if (fetchPart) requests.push(partStore.fetchPartsByOrderId(orderId.value))
    Promise.all(requests).then(() => {
      loading.value = false
      // remove uploaded files that already done
      uploadedFiles.value = uploadedFiles.value.filter(f => f.status !== uploadFileStatus.DONE)

      autoSelectOption(batches.value.map(getId))

      if (fetchFile) {
        const fileIds: number[] = []
        forEach(batches.value, (batch) => {
          fileIds.push(getId(batch.part?.source))
        })
        fileStore.fetchByIds(uniq(fileIds))
      }
    }).catch()
  }

  const updateOrderDetail = debounce(async () => {
    if (!orderDetail.value) return
    await orderStore.update({ id: orderDetail.value.id, reference: inputReference.value, description: inputDescription.value })
  }, 1000)
  
  function updateMachines(data, id, options: OptimisticUpdateOptionInterface = {}) {
    return new Promise(async (resolve, reject) => {
      const batch = batches.value.find(batch => batch.id === id)

      if (data === null) {
        const machine = await  batchStore.update({
          id,
          machines: batch.operations,
        }, options)
        resolve(machine)
        return
      }

      const machines = Array.isArray(data)
        ? [batch.process].filter(Boolean).concat(data)
        : batch.operations.concat(data)

      const machine = await batchStore.update({ id, machines }, options)
      resolve(machine)
    })
  }
  function updateStock(data: any, id: number, do_patch: boolean = true) {
    return new Promise((resolve) => {
      const index = batchStore.all.findIndex(b => b.id === id)
      if (index === -1) return

      const wasFilled = batchStore.all[index].injected_thickness && batchStore.all[index].injected_material

      if (data?.type === "size")
        batchStore.all[index].injected_thickness = data.value
      else
        batchStore.all[index].injected_material = data?.id

      if (!do_patch) return resolve(batchStore.all[index])

      // remove stock if previously filled material and thickness then one of them unselected
      if (
        wasFilled
        && (
          !batchStore.all[index].injected_material
          || !batchStore.all[index].injected_thickness
        )
      ) {
        // immediate update some fields if stock null
        // not inserted into batchStore.update because don't want to send these to axios request payload
        batchStore.all[index].unit_amount = 0
        batchStore.all[index].total_amount = 0
        batchStore.all[index].is_valid = false

        batchStore.update({ id, stock: null })
        return resolve(true)
      }

      // if one of material or thickness still empty, assumed it still configuring, not doing any patch update
      if (!batchStore.all[index].injected_material || !batchStore.all[index].injected_thickness) {
        autoSelectOption([id])
        return resolve(batchStore.all[index])
      }

      const mappedBatchIndex = batchStore.mappedData.findIndex(b => b.id === id)
      const selectedMaterial = batchStore.mappedData[mappedBatchIndex].injected_material

      const materialOptions = batchStore.mappedData[mappedBatchIndex].options.stock.filter(stock => stock.name.trim() === selectedMaterial?.name.trim())
      const stock = materialOptions.find(m => 
        (data?.type === "size" && m.thickness === data?.value && selectedMaterial?.name.trim() === m.name.trim())
        || (data?.type !== "size" && m.id === data?.id)
      )

      if (!stock) {
        console.error(`stock with these material and thickness configuration not found`)
        return 
      }
      batchStore.update({ id, stock: stock.id })
      resolve(true)
    })
  }
  async function uploadEnd(originalFile: File, file: File) {
    addOrUpdate(fileStore.all, file, ["id"])

    // check duplicate files by originalFile and id
    const foundFile = uploadedFiles.value.find(f => f.originalFile === originalFile)
    if (!foundFile.force) {
      const isDuplicated = uploadedFiles.value.some(f => batches.value.some(batch => batch.part?.source?.id === file?.id) || f.file?.id === file.id)
      if (isDuplicated) {
        updateUploadedFiles({ originalFile, file, status: uploadFileStatus.DUPLICATED })
        if (!uploadedFiles.value.some(f => [uploadFileStatus.UPLOADING, uploadFileStatus.PROCESSING].includes(f.status))) onDone()
        return
      }
    }

    updateUploadedFiles({ originalFile, file, status: uploadFileStatus.PROCESSING, progress: 25 })
    if (uploadedFiles.value.some(f => f.status === uploadFileStatus.UPLOADING)) return
    
    let order = orderDetail.value as Order || fileCreatedOrder.value

    const manufacturer = authStore.authenticatedUser?.is_manufacturer
      ? authStore.authenticatedUser?.organization.id
      : authStore.authenticatedUser?.parentManufacturer?.id
    const customerOrganizationId = authStore.authenticatedUser?.is_manufacturer
      ? selectedCustomer.value.id
      : authStore.authenticatedUser?.organization.id

    if (!!order) {
      updateUploadedFiles({ originalFile, file, status: uploadFileStatus.PROCESSING })
      Promise.allSettled(
        uploadedFiles.value.filter(f => f.status === uploadFileStatus.PROCESSING).map(f => createBatchAndPart(f.originalFile, f.file))
      )
        .catch()
        .finally(() => onDone())
    }
    else if (fileIsCreatingOrder.value) {
      updateUploadedFiles({ originalFile, file, status: uploadFileStatus.PROCESSING })
    }
    else {
      updateUploadedFiles({ originalFile, file, status: uploadFileStatus.PROCESSING })

      loading.value = true
      document.body.classList.add("busy")
      fileIsCreatingOrder.value = true

      const customer = organizationStore.mappedData.find(
        organization => organization.id === customerOrganizationId
      )
      /**
       * This address is actually a PICKUP address, which is address of manufacturer
       * The SHIPPING/Delivery address will always be using customer's address as per Hans request
       */
      const addresses = organizationStore.mappedData.find(
        organization => organization.id === manufacturer
      )?.addresses || []
      const address = addresses.find(
        address => address.is_active && address.is_shipping
      )

      order = fileCreatedOrder.value = await orderStore.add({
        customer,
        description: orderDescription.value,
        manufacturer,
        reference: orderReference.value,
        delivery_address: address,
        delivery_type: "PICKUP",
        delivery_date: new Date(new Date().getFullYear(), 11, 31),
      }) as Order

      fileIsCreatingOrder.value = false

      Promise.allSettled(
        uploadedFiles.value.filter(f => f.status === uploadFileStatus.PROCESSING).map(f => createBatchAndPart(f.originalFile, f.file))
      )
        .catch()
        .finally(() => onDone())
    }

    async function createBatchAndPart(originalFile, file) {
      return new Promise(async (resolve, reject) => {
        const removeFile = () => {
          if ($neworderDropzone.value) {
            $neworderDropzone.value.removeFile(originalFile)
          }
        }
        try {
          loading.value = true

          const part = await partStore.add({ source: file })
          updateUploadedFiles({ originalFile, file, progress: 50 })
          await batchStore.add({ manufacturer, order, part, quantity: 1 }, { withoutOptimistic: true })
          updateUploadedFiles({ originalFile, file, progress: 75 })
          
          removeFile()

          if (part.tree?.is_assembly) {
            await handleAssembly(part as Part)
            if (orderId.value) partStore.fetchPartsByOrderId(orderId.value)
          }

          updateUploadedFiles({ originalFile, file, progress: 100, status: uploadFileStatus.DONE })

          resolve(true)
        } catch (error: any) {
          removeFile()
          updateUploadedFiles({ originalFile, file, status: uploadFileStatus.ERROR, error: error.message })

          reject(error)
        }
      })
      async function handleAssembly(part: Part) {
        // sub parts and sub assemblies have different data sources
        // sub assemblies data is taken from the main assembly's sub_assemblies property
        // sub parts data is taken by generating it via the POST /parts/{id}/parts endpoint
        const subParts: Part[] = [
          ...part.sub_assemblies || [],
          ...await partStore.addSubPart(part.id, { source: getId(part.source) })
        ]
        const chunks = chunk(subParts, 8)

        for (const chunk of chunks) {
          await Promise.allSettled(
            chunk.map(subPart => {
              const count = subPart.tree?.is_assembly ? subPart.tree.count : PartHelpers.countTotalByProperty(part.tree, 0, subPart.parent.reference, "reference")

              return batchStore.add(
                {
                  manufacturer,
                  order,
                  part: subPart,
                  quantity: count || 1,
                },
                {
                  withoutOptimistic: true,
                  onSuccess: () => {
                    const processedFile = uploadedFiles.value.find(f => f.originalFile === originalFile)
                    const progress = Math.floor(processedFile.progress + (25 / (subParts.length || 1)))
                    updateUploadedFiles({ originalFile, file, progress: progress >= 100 ? 99 : progress })
                  }
                }
              )
            })
          )
        }
      }
    }
    function onDone() {
      fileIsCreatingOrder.value = false
      fileCreatedOrder.value = null

      autoSelectOption(batches.value.map(b => b.id))
      document.body.classList.remove("busy")
      loading.value = false
      nextTick(() => {
        if (route.fullPath.endsWith("new")) router.push(`/orders/${order.id}/edit`)
        else fetchBatches(false, false)
      })
    }
  }
  async function processDuplicateFile(file) {
    updateUploadedFiles({ originalFile: file.originalFile, file: file.file, status: uploadFileStatus.PROCESSING, force: true })
    nextTick(() => uploadEnd(file.originalFile, file.file))
  }
  async function setRow(id: number, rowData: any) {
    return new Promise((resolve, reject) => {
      const { whitelist } = mergerOptions.value
      const idColumn = mergerDefaultIdColumn
      let oldRow = batches.value.find(d => id == d[idColumn]) as any
      if (oldRow) oldRow = pick(oldRow, whitelist)
      rowData = pick(rowData, whitelist)
    
      if (!oldRow) return

      const changes = pickBy(rowData, (value, key) => oldRow[key] != value && !isEqual(oldRow[key], value))
      if (!Object.keys(changes).length) return resolve({})

      const batch = batches.value.find(b => b.id === id)
      const { error, batchPayload, partPayload } = generateMergerPayload({ id, batch, changes })
      if (error) return reject({})

      const updates = []
      if (Object.keys(batchPayload).length > 0) updates.push(batchStore.update({ id, ...batchPayload }))
      if (Object.keys(partPayload).length > 0) updates.push(partStore.update({ id: getId(batch.part), ...partPayload }))
      if (updates.length === 0) return resolve({})

      Promise.all(updates)
        .then(() => {
          batchStore.injectValues([id])
          resolve({})
        })
        .catch(reject)
    })
  }
  function onFileAdded(file) {
    document.body.classList.add('busy');
    loading.value = true;
    uploadedFiles.value.push({
      file,
      filename: file.name,
      originalFile: file,
      status: uploadFileStatus.UPLOADING,
      error: null,
      progress: 0, // in percent
      force: false // force upload file even if the file duplicate
    })
  }
  function onFileError(file: any, error: any) {
    const { xhr: response } = file
    const status = response?.status || 500

    updateUploadedFiles({
      originalFile: file,
      status: uploadFileStatus.ERROR,
      // Backend return error message inside `status` property
      error: typeof error === "string" ? error : error?.status || error?.message || errorHandler.generateErrorMessage(status)
    })

    if (!uploadedFiles.value.some(f => f.status === uploadFileStatus.UPLOADING)) {
      document.body.classList.remove('busy');
      loading.value = false;
    }

    if (status === httpStatusCodes.UNAUTHORIZED) {
      authStore.logout()
    }
  }
  function updateUploadedFiles(payload: updateUploadedFileInterface) {
    uploadedFiles.value = uploadedFiles.value.map(f => {
      if (f.originalFile === payload.originalFile) {
        f = {
          ...f,
          ...payload
        }
      }
      return f
    })
  }
/** End Functions */

/** Watches */
  watchEffect(() => (selectedCustomer.value ??= orderDetail.value?.customer))
  watchEffect(() => {
    if (!showModals.value.customerPicker) selectFileAfterPick.value = false
  })
  watch(() => orderId.value, () => {
    if (!orderId.value) uploadedFiles.value = uploadedFiles.value.filter(f => f.status !== uploadFileStatus.ERROR)
    if (orderId.value) {
      fetchBatches()
      orderStore.fetchByIds([orderId.value])
        .then(results => {
          // refresh table when order and batches are fetched
          refreshKey.value = uuidv4()

          const order = results[0]
          if (order.status !== "CREATED")
            orderStore.update({ id: orderId.value, status: "CREATED" })
        })
        .catch()
    }
  })
  watch(() => route.params.id,() => {
    orderId.value = +route.params.id || null
    showModals.value.customerPicker = false
    nextTick(() => {
      if (authStore.authenticatedUser?.role === "manufacturer") {
        selectedCustomer.value = null
        showModals.value.customerPicker = true
      }
    })
  })
  watch(() => !isReady.value || !authStore.authenticatedUser, () => {
    orderId.value = +route.params.id || null
    showModals.value.customerPicker = false
    selectedCustomer.value = null

    // nextTick usage to give some time for UI update
    nextTick(() => {
      // Role is not customer then if not on edit order, show the customer picker modal
      if (authStore.authenticatedUser?.role === "manufacturer" && !orderId.value)
        return (showModals.value.customerPicker = true)

      selectedCustomer.value = orderDetail.value?.customer || authStore.authenticatedUser?.parentManufacturer || null
    })
  })
  watch(selectedCustomer, () => {
    nextTick(() => {
      if (!selectedCustomer.value) return globalStore.reset(["wholePageDropzone"])
      globalStore.wholePageDropzone = {
        ...globalStore.wholePageDropzone,
        options: dropzoneOptions,
        enabled: true,
        actions: {
          ...globalStore.wholePageDropzone.actions,
          success: uploadEnd as any,
          addedfile: onFileAdded,
          error: onFileError,
        }
      }
    })
  })
  // disable whole page dropzone if merger modal is opened
  watch(() => showModals.value.merger, () => {
    globalStore.wholePageDropzone.enabled = !showModals.value.merger
  })
/** End Watches */

onMounted(() => {
  isReady.value = true
  showModals.value.customerPicker = false
  batchStore.all = []
  orderId.value = +route.params.id || null
})

onUnmounted(() => {
  globalStore.reset(["wholePageDropzone"])
})
</script>

<style lang="scss">
@import "./styles/new.scss";
</style>