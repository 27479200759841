import { defineStore } from "pinia"
import axios from "axios"
import { saveAs } from "file-saver"
import { forEach, union, difference, cloneDeep, remove as _remove } from "lodash-es"
import type { Batch, CreateBatch, Machine, Part, SizeOption } from "@/interfaces"
import type { OptimisticUpdateOptionInterface , InjectedBatchInterface, OptimisticAddOptionInterface } from "@/interfaces"
import {
  fileStore,
  machineStore,
  orderStore,
  partStore,
  profileStore,
  settingStore,
  sheetStore,
  batchStore,
  requestStore,
  toastStore,
  globalStore,
} from "."
import { getId } from "@/interfaces/_utils"
import { addOrUpdate, batchPrepareUpdatePayload, optimisticAdd, optimisticDelete, optimisticUpdate } from "@/libraries/helpers"
import { getCache, setCache, getBatchTreeBasedOnPartTree } from "@/libraries/helpers"
import { i18n } from "@/plugins/i18n"

const cacheKey = "batches"

const makeThickness = (thickness?: number, is_visible = true): SizeOption =>
  !thickness
    ? undefined
    : {
        value: parseFloat(thickness.toFixed(2)),
        name: `${thickness.toFixed(2)} mm`,
        type: "size",
        is_visible,
      }

export const mapBatch = (batch: InjectedBatchInterface) =>  {
  const allStocks = [
    ...sheetStore.mappedManufacturerSheets,
    ...profileStore.mappedManufacturerProfiles,
  ]
  
  let stock = null
  let injected_material = null
  for (let i = 0; i < allStocks.length; i++) {
    const v = allStocks[i];
    if (v.id === getId(batch.stock)) stock = v
    if (v.id === getId(batch.injected_material)) injected_material = v
  }

  const mappedBatch = {
    ...batch,
    isTemporary: batch.id.toString().includes("temp"),
    isDummy() {
      return this.id.toString().includes("dummy")
    },
    injected_material,
    injected_thickness: makeThickness(batch.injected_thickness),
    part: partStore.mappedData.find(part => part?.id === getId(batch.part)),
    stock,
    get thickness() {
      return makeThickness(this.stock?.thickness || this.part?.section?.thickness || this.part?.pattern?.thickness)
    },
    get partBasedParentId() {
      const partBasedParentId = (batchStore.mappedData || []).find(b => b.part?.id === this.part?.parent?.part?.id)?.id
      // don't set parent if parent is itself to avoid infinite loop
      return partBasedParentId !== this.id ? partBasedParentId : null
    },
    get parent() {
      return (batchStore.mappedData || []).find(b => b.id === getId(batch.parent))
    },
    getOrder() {
      return orderStore.mappedData.find(order => order.id === batch.order)
    },
    machines:
      machineStore.mappedManufacturerMachines.filter(machine =>
        batch?.machines?.map(m => getId(m)).includes(machine.id)
      ) || [],
    get process() {
      return this.machines.find(machine => machine.type === "CUT")
    },
    get operations() {
      return this.machines.filter(machine => machine.type !== "CUT")
    },
    times: batch.times?.map(time => ({
      ...time,
      setting: settingStore.mappedData.find(setting => setting.id === getId(time.setting)),
      machine: machineStore.mappedManufacturerMachines.find(machine => machine.id === getId(time.machine)),
    })),
    get options() {
      const stock = []
      const stockIds = batch.options?.stock?.map(s => getId(s)) || []
      forEach(allStocks, s => {
        if (stockIds.includes(s.id)) {
          stock.push(s)
        }
      })

      const machines = []
      const machineIds = batch?.options?.machines?.map(m => getId(m)) || []
      const processes = []
      const operations = []
      forEach(machineStore.mappedManufacturerMachines, machine => {
        if (machineIds.includes(machine.id)) {
          machines.push(machine)
          if (machine.type === "CUT") processes.push(machine)
          if (machine.type !== "CUT") operations.push(machine)
        }
      })

      const chosenStock = mappedBatch.stock
      const stocksBasedOnChosenStock = stock.filter(stock => stock.name.trim() === (chosenStock?.name || "").trim())

      const chosenMaterial = mappedBatch.injected_material
      const stocksBasedOnChosenMaterial = stock.filter(
        stock => stock.name.trim() === (chosenMaterial?.name || "").trim()
      )

      const chosenCertificates = this.certificates

      return {
        ...batch.options,
        stock,
        machines,
        processes: processes.sort((a, b) => a.priority - b.priority),
        operations: operations.sort((a, b) => a.priority - b.priority),
        get materials() {
          const sizeOptions = this.thicknesses
          const chosenThickness =
            batch.injected_thickness || (sizeOptions.length > 1 ? undefined : sizeOptions[0]?.value)
          return this.stock
            .filter(stock => (chosenThickness ? stock.thickness === chosenThickness : true))
            .filter(stock =>
              chosenCertificates.length > 0 ? difference(chosenCertificates, stock.certificates).length === 0 : true
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((stockOptions, stock) => {
              // Hans wanted to group by stock name instead of underlying material
              const stockOption = stockOptions.find(option => option.name.trim() === stock.name.trim())
              if (stockOption) {
                stockOption.is_visible ||= stock.is_visible
              } else {
                stockOptions.push({ ...stock })
              }
              return stockOptions
            }, [])
        },
        get thicknesses() {
          let options = (chosenMaterial ? stocksBasedOnChosenMaterial : this.stock)
            .filter(stock =>
              chosenCertificates.length > 0 ? difference(chosenCertificates, stock.certificates).length === 0 : true
            )
            .reduce((sizeOptions, stock) => {
              const sizeOption = sizeOptions.find(size => Math.abs(size.value - stock.thickness) < 0.01)
              if (sizeOption && stock.is_visible) {
                sizeOption.is_visible = true
              } else if (!sizeOption) {
                sizeOptions.push(makeThickness(stock.thickness, stock.is_visible))
              }
              return sizeOptions
            }, [] as SizeOption[])
          const partThickness = mappedBatch.part?.section?.thickness || mappedBatch.part?.pattern?.thickness
          if (partThickness) {
            const patternThickness = options.find(o => Math.abs(o.value - partThickness) < 0.01)
            if (patternThickness) patternThickness.is_visible = true
            else options.push(makeThickness(partThickness))
          }
          return options.sort((a, b) => a.value - b.value)
        },
        get certificates() {
          const sizeOptions = this.thicknesses
          const chosenThickness =
            mappedBatch.stock?.thickness || (sizeOptions.length > 1 ? undefined : sizeOptions[0]?.value)
          return (chosenStock ? stocksBasedOnChosenStock : this.stock)
            .filter(stock => (chosenThickness ? stock.thickness === chosenThickness : true))
            .reduce((_, stock) => {
              return union(_, stock.certificates)
            }, [])
        },
      }
    },
    exports: fileStore.all.filter(file => batch.exports?.map(e => getId(e)).includes(file?.id)),
    get certificateOptions() {
      return (
        this.certificates?.map(c => ({
          id: c,
          name: c.replace(/.*(\d)(\d)/, "$1.$2"),
        })) || []
      )
    },
    get is3D() {
      // stp, step is 3D; dxf is not 3D
      // tube, sheet is 3D; assembly is not 3D
      return (this.part?.source?.extension || "").toLowerCase() !== "dxf" && (this.part?.type || "") !== "ASSEMBLY"
    },
  }
  return mappedBatch
}

export default defineStore("batch", {
  state: () => ({
    all: [],
    updateRequests: {
      queue: [],
      processing: null,
      isCancelled: false,
    },
  }),
  getters: {
    mappedData: state => state.all.map((b, i) => ({ ...mapBatch(b), index: i + 1 })),
    /* 
      treeviewData need to be separated from mappedData because treeviewData is used for treeview table, which depends on the part tree
      treeviewData can have multiple duplicate batches, which if the one changed, the same batches will be changed too
    */
    treeviewData() {
      return getBatchTreeBasedOnPartTree((this as any).mappedData)
    },
    getBatchesByOrderId() {
      return (orderId: number, treeview: boolean = false) =>
        (this[!treeview ? "mappedData" : "treeviewData"] || []).filter(batch => batch.order === orderId)
    },
  },
  actions: {
    fetchBatchesByOrderId(orderId: number) {
      return new Promise((resolve, reject) => {
        if (!orderId) return reject("Error: order not found")

        // show cached batches first until fetching done
        if (this.all.length === 0)
          this.all = ((getCache(cacheKey)?.data || []) as Batch[]).filter(b => b.order === orderId)

        axios
          .get(`/v1/orders/${orderId}/batches`)
          .then(async ({ data }) => {
            const ids = data.map(b => b.id)
            let batches = cloneDeep(
              this.all.filter(b => (ids.includes(b.id) && b.order === orderId) || b.order !== orderId)
            )

            forEach(data || [], v => addOrUpdate(batches, v, ["id"]))

            this.all = batches
            await this.injectValues()
            resolve(true)
          })
          .catch(reject)
          .finally(() => setCache(cacheKey, this.all))
      })
    },
    add(values: CreateBatch, options: OptimisticAddOptionInterface) {
      if (values.order) values.order = getId(values.order)
      if (values.part) values.part = getId(values.part) as unknown as Part
      if (values.machines) values.machines = values.machines.map(getId) as unknown as Machine[]
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/batches",
        onSuccess: async data => {
          if (!options?.withoutOptimistic) {
            await this.injectValues([data.id])
            setCache(cacheKey, this.all)
          }
        },
        ...options,
      })
    },
    async update(values: Partial<Batch> & Required<Pick<Batch, "id">>, options: OptimisticUpdateOptionInterface) {
      values = batchPrepareUpdatePayload(
        values,
        this.all.find(b => b.id === values.id)
      )

      const requiredOptions = {
        allObjects: [this.all],
        values,
        url: `/v1/batches/${values.id}`,
      }

      // update existing queue
      _remove(this.updateRequests.queue, q => q.id === values.id)
      this.updateRequests.queue.push({
        id: values.id,
        values,
        options,
      })

      // handle if still processing
      if (this.updateRequests.processing) {
        if (this.updateRequests.processing === values.id) {
          requestStore.cancelRunningFetches([`/v1/batches/${values.id}`])
          this.updateRequests.isCancelled = true
        }

        return optimisticUpdate({
          ...requiredOptions,
          onlyOptimistic: true,
        }).catch()
      }
      this.updateRequests.processing = values.id

      return optimisticUpdate({
        ...requiredOptions,
        onSuccess: () => setCache(cacheKey, this.all),
        addToRequestStore: true,
        resetOnCancelled: false,
        ...options,
      })
        .catch()
        .finally(() => {
          if (!this.updateRequests.isCancelled) {
            _remove(this.updateRequests.queue, q => q.id === values.id)
          }

          this.updateRequests.processing = null
          this.updateRequests.isCancelled = false

          if (this.updateRequests.queue.length > 0) {
            const { values, options } = this.updateRequests.queue[0]
            this.update(values, options)
          }
        })
    },
    remove(id: number) {
      _remove(this.updateRequests.queue, q => q.id === id)
      requestStore.cancelRunningFetches([`/v1/batches/${id}`])
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/batches/${id}`,
        id,
        onSuccess: () => {
          setCache(cacheKey, this.all)

          // Update child batches if parent deleted
          const childBatches = this.all.filter(b => b.parent === id)
          forEach(childBatches, batch => {
            this.update({ id: batch.id, parent: null }, {})
          })
        },
      })
    },
    exports(batch: Batch, type: string, target: "download" | "preview-pdf" = "download") {
      return new Promise(resolve => {
        if (target === "preview-pdf") {
          globalStore.pdfViewer.show = true
          globalStore.pdfViewer.loading = true
          globalStore.pdfViewer.filename = `Batch PDF`
        }
        axios
          .get(`/v1/batches/${batch.id}/exports?type=${type}`)
          .then(({ data }) => {
            const extension = type === "CYCAD" ? "dxf" : type.toLowerCase()
            const filename = `${batch.part.source.name}_${batch.part.name}.${extension}`
            const mimetype = `application/${type === "STP" ? "STEP" : type.toLowerCase()}`
            const blob = new Blob([data], { type: mimetype })

            if (target === "download") {
              saveAs(blob, filename)
              resolve(filename)
            }
            if (target === "preview-pdf") {
              const url = URL.createObjectURL(blob)
              globalStore.pdfViewer.filename = filename
              globalStore.pdfViewer.fileUrl = url
              resolve(url)
            }
          })
          .catch(({ response }) => {
            toastStore.toasts.push({
              color: "danger",
              message: i18n.t("failed_to_get_file", {
                msg: response?.data?.message,
              }),
            })
            resolve(null)
          })
          .finally(() => (globalStore.pdfViewer.loading = false))
      })
    },
    /* 
      this function is to inject some values that needed for the front-end
      for example the material and thickness are depends on stock value,
        but if using raw stock value, it can't handle some scenario
    */
    injectValues(ids: number[] = null) {
      return new Promise(resolve => {
        const allBatches = cloneDeep(this.all)
        for (let i = 0; i < this.mappedData.length; i++) {
          const v = this.mappedData[i]

          if (ids && !ids.includes(v.id)) continue

          const allBatchIndex = allBatches.findIndex(b => b.id === v.id)

          allBatches[allBatchIndex].injected_material = getId(v.stock)
          allBatches[allBatchIndex].injected_thickness = v.stock?.thickness
          allBatches[allBatchIndex].needUpdate = false

          let changes = []

          if (!allBatches[allBatchIndex].injected_material && v.options?.materials.length === 1) {
            allBatches[allBatchIndex].injected_material = v.options?.materials[0].id
            changes.push("material")
          }

          if (!allBatches[allBatchIndex].injected_thickness && v.options?.thicknesses.length === 1) {
            allBatches[allBatchIndex].injected_thickness = v.options?.thicknesses[0].value
            changes.push("thickness")
          }

          if (changes.length > 1) {
            allBatches[allBatchIndex].needUpdate = true
          }
        }
        this.all = allBatches
        resolve(this.mappedData)
      })
    },
  },
})
