<template>
  <div v-if="props.batch.part" class="wrapped-with-gnaarmyqvy">
    <span
      v-if="image3D"
      :id="`element-${props.batch.id}`"
      class="part-tooltip"
      :class="{
        openable
      }"
      @click="showModal = openable"
      @mouseenter="handleMouseEvent('enter', props.batch)"
      @mouseleave="handleMouseEvent('leave', props.batch)"
      v-html="image3D"
    />
    <CSpinner
      v-else
      :id="`element-${props.batch.id}`"
      :class="{
        openable
      }"
      @mouseenter="handleMouseEvent('enter', props.batch)"
      @mouseleave="handleMouseEvent('leave', props.batch)"
      @click="showModal = openable"
    />
    <PartImageModal
      v-if="showModal"
      :batch="props.batch"
      @close="showModal = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, ref, computed } from "vue-demi"
import { debounce } from "lodash-es"
import { type Batch, partType } from "@/interfaces"
import { partStore, globalStore } from "@/store"
import PartImageModal from "@/components/part-image/PartImageModal.vue"
import { sleep } from "@/libraries/helpers"

const props = withDefaults(
  defineProps<{
    batch: Batch
    clickable?: boolean
  }>(),
  {
    clickable: true
  }
)

const openable = computed<boolean>(
  () => props.clickable && props.batch.part?.source?.extension !== "dxf"
)

const isFetchingImages = ref(false)
const isImageFetched = ref(false)
const showModal = ref(false)

const image3D = computed(() => partStore.all3DImages[props.batch?.part?.id])

watch(
  () => props.batch.part,
  () => {
    fetchImages()
  }
)

const handleMouseEvent = debounce(async (type: string, batch: Batch) => {
  const show = type === "enter"
  globalStore.setComponent("popover", { show, batch })

  const element = document.getElementById(`element-${batch.id}`)
  const popoverElement = document.getElementById("global-popover")

  await sleep(10) // make the ui update first

  const elementBoundingRect = element.getBoundingClientRect()
  const popoverElementBoundingRect = popoverElement.getBoundingClientRect()
  const top = (elementBoundingRect.top - (popoverElementBoundingRect.height / 2)) * 1.25
  const left = (elementBoundingRect.left + elementBoundingRect.width + 20) * 1.25
  popoverElement.style.left = `${left > 0 ? left : 0}px`
  popoverElement.style.top = `${top > 0 ? top : 0}px`
}, 100)

const fetchImages = async() => {
  if (props.batch.part && !isFetchingImages.value && !isImageFetched.value) {
    isFetchingImages.value = true
    isImageFetched.value = false
    await partStore.get3DImagesByIds([props.batch.part.id])
    if (props.batch.part.type === partType.SHEET) await partStore.get2DImagesByIds([props.batch.part.id])
    isFetchingImages.value = false 
    isImageFetched.value = true
  }

}

onMounted(() => {
  fetchImages()
})
</script>

<style lang="scss">
.wrapped-with-gnaarmyqvy {
  .part-tooltip {
    height: 100%;
    width: 100%;
    display: flex;
  }
  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  .part-tooltip svg {
    max-width: 50px;
    max-height: 50px;
  }
  .openable {
    cursor: pointer;
  }
}
</style>
