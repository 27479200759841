import { defineStore } from "pinia"
import { chunk, cloneDeep, forEach, remove as _remove } from "lodash-es"
import axios from "axios"
import { saveAs } from "file-saver"
import type { CreateOrder, Order } from "@/interfaces"
import { partType } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { organizationStore, requestStore, batchStore, addressStore, authStore, toastStore, globalStore } from "."
import { addOrUpdate, getId, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch, sleep } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"
import { orderCosts } from "@/views/orders/orderCosts"
import { i18n } from "@/plugins/i18n"
import JSZip from "jszip"

const cacheKey = "orders"

const defaultSummary = {
  created: { count: 0, total_price: "0.00" },
  in_review: { count: 0, total_price: "0.00" },
  confirmed: { count: 0, total_price: "0.00" },
  quoted: { count: 0, total_price: "0.00" },
  ordered: { count: 0, total_price: "0.00" },
  produced: { count: 0, total_price: "0.00" },
  completed: { count: 0, total_price: "0.00" },
  cancelled: { count: 0, total_price: "0.00" },
}

const mapOrder = (order: Order) => ({
  ...order,
  batches: batchStore.mappedData.filter(batch =>
    order.batches?.map(getId).includes(batch.id)
  ),
  total_parts: (order.batches || []).length,
  customer: organizationStore.mappedData.find(org => org.id === getId(order.customer)),
  manufacturer: organizationStore.mappedData.find(org => org.id === getId(order.manufacturer)),
  delivery_address: addressStore.all.find(addr => addr.id === getId(order.delivery_address)),
  get formatted_total_amount() {
    return orderCosts(this)
  }
})

export default defineStore("order", {
  state: () => ({
    all: [],
    statusFilters: ["CONFIRMED", "ORDERED", "IN_REVIEW", "QUOTED", "CREATED", "PRODUCED"],
    summary: {}
  }),
  getters: {
    mappedData: state => state.all.map(mapOrder),
    getOrderById() {
      return (id: number) => this.mappedData.find(m => m.id === id)
    },
    url: () => (authStore.authenticatedUser?.role === "customer" ? "/v1/orders" : "/v1/customers/orders"),
  },
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Order[]
    },
    async fetchAll({ persist = false } = {}) {
      await paginatedFetch({
        url: this.url,
        persist,
        queryParams: {
          order_by: "created",
          ascending: false,
          status: this.statusFilters,
        },
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchByStatus({ status = "CREATED", persist = false } = {}) {
      await paginatedFetch({
        url: `${this.url}?status=${status}`,
        persist,
        callback: data => {
          _remove(data, d => !this.statusFilters.includes(d.status))
          forEach(data || [], v => addOrUpdate(this.all, v, ["id"]))
        },
        queryParams: {
          order_by: "created",
          ascending: false,
        },
        runCallbackCondition: data => {
          const dataByStatus = this.all.filter(d => d.status === status)
          return dataByStatus.length === 0 || data.length > UPDATE_DATA_THRESHOLD
        },
      })
      setCache(cacheKey, this.all)
    },
    fetchByIds(ids: number[]) {
      return new Promise((resolve, reject) => {
        const requests = []
        forEach(ids, id => requests.push(axios.get(`/v1/orders/${id}`)))

        Promise.all(requests)
          .then(responses => {
            const orders = responses.map(r => r.data)
            forEach(orders || [], v => addOrUpdate(this.all, v, ["id"]))
            resolve(orders)
          })
          .catch(reject)
          .finally(() => setCache(cacheKey, this.all))
      })
    },
    statusFilterChanged({ status, added = true }) {
      if (!added) {
        requestStore.cancelRunningFetches([`${this.url}?status=${status}`])
        requestStore.removeRunningFetch(`${this.url}?status=${status}`)
        _remove(this.all, { status })
        return
      }
      this.fetchByStatus({ status })
    },
    add(values: CreateOrder) {
      if (values.customer) values.customer = getId(values.customer)
      if (values.manufacturer) values.manufacturer = getId(values.manufacturer)
      if (values.delivery_address) values.delivery_address = getId(values.delivery_address)
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/orders",
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    update(values: Partial<Order> & Required<Pick<Order, "id">>) {
      if (values.customer) values.customer = getId(values.customer)
      if (values.manufacturer) values.manufacturer = getId(values.manufacturer)
      if (values.delivery_address) values.delivery_address = getId(values.delivery_address)
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/orders/${values.id}`,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    exports(orderId: number, target: "download" | "preview-pdf" = "download") {
      if (target === "preview-pdf") {
        globalStore.pdfViewer.show = true
        globalStore.pdfViewer.loading = true
        globalStore.pdfViewer.filename = `${orderId}.pdf`
      }
      return new Promise(resolve => {
        if (!orderId) {
          toastStore.toasts.push({
            color: "danger",
            message: i18n.t("failed_to_get_file", {
              msg: i18n.t("order_not_found"),
            }),
          })
          globalStore.pdfViewer.loading = false
          resolve(null)
          return
        }
        axios
          .get(`/v1/orders/${orderId}/exports`)
          .then(({ data }) => {
            const blob = new Blob([data], { type: "application/pdf" })
            if (target === "download") {
              const fileName = `${orderId}.pdf`
              saveAs(blob, fileName)
              resolve(fileName)
            }
            if (target === "preview-pdf") {
              const url = URL.createObjectURL(blob)
              globalStore.pdfViewer.fileUrl = url
              resolve(url)
            }
          })
          .catch(({ response }) => {
            toastStore.toasts.push({
              color: "danger",
              message: i18n.t("failed_to_get_file", {
                msg: response?.data?.message,
              }),
            })
            resolve(null)
          })
          .finally(() => (globalStore.pdfViewer.loading = false))
      })
    },
    exportZip(orderId: number) {
      return new Promise(resolve => {
        const order = this.mappedData.find(o => o.id === orderId)
        if (!order) {
          toastStore.toasts.push({
            color: "danger",
            message: i18n.t("failed_to_get_file", {
              msg: i18n.t("order_not_found"),
            }),
          })
          resolve(null)
          return
        }

        var zip = new JSZip()
        let total = 0
        let completed = 0
        const existingFilenames = []
        let abort = false

        const requests = [
          {
            endpoint: `/v1/orders/${order.id}/exports`,
            name: `${order.id}_${order.reference}_${order.description}`,
            extension: ".pdf",
          },
          {
            endpoint: `/v1/orders/${order.id}/json`,
            name: `${order.id}_${order.reference}_${order.description}`,
            extension: ".json",
          },
        ]
        forEach(batchStore.mappedData, batch => {
          requests.push({
            endpoint: `/v1/batches/${batch.id}/exports?type=STP`,
            name: `${batch.part?.filename}_${batch.part?.name}`,
            extension: ".stp",
          })
          if (batch.part?.type === partType.SHEET) {
            requests.push({
              endpoint: `/v1/batches/${batch.id}/exports?type=DXF`,
              name: `${batch.part?.filename}_${batch.part?.name}`,
              extension: ".dxf",
            })
          }
        })

        total = requests.length

        toastStore.toasts.push({
          id: `${order.id}-zip`,
          type: "loading",
          color: "dark",
          message: i18n.t("preparing_files_totals", { total, completed }),
          delay: 300000,
          dismissable: false,
        })
        const toastIndex = toastStore.toasts.findIndex(toast => toast.id === `${order.id}-zip`);

        (async () => {
          const chunks = chunk(requests, 5)
          for (const chunk of chunks) {
            if (abort) break
            await Promise.allSettled(chunk.map(req => getFileAxios(req.endpoint, req.name, req.extension))).catch(
              handleError
            )
          }
          if (abort) return
          zip
            .generateAsync({ type: "blob" })
            .then(function (content) {
              saveAs(content, `${order.id}_${order.reference}_${order.description}`)
              toastStore.toasts.splice(toastIndex, 1)
            })
            .catch(handleError)
        })()
        /** Functions */
        function updateCompleted() {
          if (abort) return
          
          completed++
          toastStore.toasts[toastIndex].message = i18n.t("preparing_files_totals", { total, completed })
        }
        function getFilename(filename: string, extension: string) {
          const count = existingFilenames.filter(s => s.includes(filename) && s.includes(extension)).length
          return `${filename}${count > 0 ? ` (${count})` : ""}${extension}`
        }
        function getFileAxios(endpoint: string, name: string, extension: string) {
          return axios
            .get(endpoint)
            .then(({ data }) => {
              const filename = getFilename(name, extension)
              existingFilenames.push(filename)
              zip.file(filename, typeof data !== "string" ? JSON.stringify(data) : data)
            })
            .catch(handleError)
            .finally(() => updateCompleted())
        }
        function handleError(err) {
          abort = true
          toastStore.toasts.push({
            color: "danger",
            message: i18n.t("failed_to_generate_zip", {
              msg: err.message,
            }),
          })
          toastStore.toasts.splice(toastIndex, 1)
        }
        /** End Functions */
      })
    },
    exportJson(orderId: number) {
      return new Promise(resolve => {
        if (!orderId) {
          toastStore.toasts.push({
            color: "danger",
            message: i18n.t("failed_to_get_file", {
              msg: i18n.t("order_not_found"),
            }),
          })
          resolve(null)
          return
        }
        axios
          .get(`/v1/orders/${orderId}/json`)
          .then(({ data }) => {
            const blob = new Blob([typeof data !== "string" ? JSON.stringify(data) : data], { type: "text/plain;charset=utf-8" })
            const fileName = `${orderId}.json`
            saveAs(blob, fileName)
            resolve(fileName)
          })
          .catch(({ response }) => {
            toastStore.toasts.push({
              color: "danger",
              message: i18n.t("failed_to_get_file", {
                msg: response?.data?.message,
              }),
            })
            resolve(null)
          })
      })
    },
    remove(id: number) {
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/orders/${id}`,
        id,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    async getSummary() {
      this.summary = cloneDeep(defaultSummary)
      return axios.get(`/v1/orders/total`)
        .then(({ data }) => {
          if (!data) return
          const { order } = data
          for (const key in defaultSummary) {
            this.summary[key] = order[key] || defaultSummary[key]
          }
        })
        .catch()
    }
  },
})
