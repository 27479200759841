import type { Batch, Machine, Part, Profile, Sheet } from "@/interfaces"
import { pick } from "lodash-es"
import { getId } from "@/interfaces"
import { v4 as uuidv4 } from "uuid"
import { DUMMY_BATCH } from "@/constants"
import { mapBatch } from "@/store/batch"

export function batchPrepareUpdatePayload(values: any, batch: Batch) {
  values = {
    ...pick(batch || {}, ["stock", "quantity", "machines", "certificates"]),
    ...values,
  }

  if (values.order) values.order = getId(values.order)
  if (values.part) values.part = getId(values.part) as unknown as Part
  if (values.machines) values.machines = values.machines.map(getId) as unknown as Machine[]
  if (values.stock) values.stock = getId(values.stock) as unknown as Sheet | Profile
  if (values.times) {
    values.times = values.times.map(time => ({
      ...time,
      machine: getId(time.machine) as unknown as Machine,
    }))
  }
  if (Object.hasOwnProperty.call(values, "parent")) values.parent = getId(values.parent)
  return values
}

/**
 * getBatchTreeBasedOnPartTree will return a tree of batches based on the part tree
 * there's a possibility that the batches are duplicated in the tree
 * This is expected behavior because the grouping only happens on the same level
 */
export function getBatchTreeBasedOnPartTree(allBatches: any[]) {
  const zeroLevelBatches = allBatches
    .filter(b => !b.parent)
    .map(b => ({
      ...b,
      treeview: { id: uuidv4(), level: 0, parent: null, childrenIds: [], quantity: 1 },
    }))
  
  let treeBatches = []
  let index = 1;
  let existingIndexes = {}
  for (let i = 0; i < zeroLevelBatches.length; i++) {
    const batch = zeroLevelBatches[i]
    if (existingIndexes.hasOwnProperty(batch.id)) index = existingIndexes[batch.id]
    existingIndexes[batch.id] = index

    const { children, indexes } = getNestedTree({
      allBatches,
      components: batch.part?.tree?.components,
      batch_parent_id: batch.id,
      treeview_parent_id: batch.treeview.id,
      parent_index: index,
      existingIndexes,
      parentIdentifier: (batch.id).toString()
    })
    existingIndexes = indexes
    treeBatches = [
      ...treeBatches,
      {
        ...batch,
        index,
        treeview: {
          quantity: 1,
          ...batch.treeview,
          childrenIds: children.map(v => v.treeview?.id || v.id),
        },
      },
      ...children,
    ]
    index = Object.keys(existingIndexes).length + 1
  }
  return treeBatches || []
}

/**
 * getNestedTree is to find the nested tree of batches based on the part tree, which is called recursively
 * Search to find the batch based on the part name on the same parent (note: reference not used because some component in tree doesn't have reference)
 * If the batch is not found, a dummy batch will be created just to fill out the tree
 */
function getNestedTree({
  allBatches,
  components = [],
  batch_parent_id,
  treeview_parent_id,
  parent_index = 0,
  existingIndexes = {},
  parentIdentifier = ""
}: {
  allBatches: any[]
  components?: any
  batch_parent_id: number
  treeview_parent_id: number
  parent_index?: number
  existingIndexes?: Record<string, number>
  parentIdentifier?: string
}) {
  let batches = []
  let index = parent_index + 1
  for (let i = 0; i < components.length; i++) {
    const component = components[i]
    const batch_name = component.name
    let batch = allBatches.find(b => b.part?.name === batch_name && getId(b.parent) === batch_parent_id)
    if (!batch) {
      const parentBatch = allBatches.find(b => b.id === batch_parent_id)
      const zeroLevelParent = allBatches.find(b => !b.parent && parentIdentifier.includes(b.id))
      const mappedBatch = mapBatch(DUMMY_BATCH as any)
      batch = {
        ...mappedBatch,
        // This format of id is used to make sure dummy batch having same id within the same zero-level tree parent
        // Which will be helpful for defining the batch index later as the same batch will have the same index
        id: `dummy-${zeroLevelParent.id || ""}-${batch_name}`,
        order: parentBatch.order,
        part: { ...(parentBatch.part || {}), name: batch_name },
        parent: batch_parent_id,
        quantity: 1,
      }
    }
    batch = { ...batch, treeview: { id: uuidv4(), level: component.level, parent: treeview_parent_id } }
    if (!existingIndexes.hasOwnProperty(batch.id)) existingIndexes[batch.id] = index

    const childComponents = component.components

    let children = []
    if (childComponents.length > 0) {
      const { children: nestedChildren, indexes } = getNestedTree({
        allBatches,
        components: childComponents,
        batch_parent_id,
        treeview_parent_id: batch.treeview.id,
        parent_index: index,
        existingIndexes,
        parentIdentifier: `${batch.id}-${parentIdentifier}`,
      })
      children = nestedChildren

      existingIndexes = { ...existingIndexes, ...indexes }
    }
    const newBatches = [
      {
        ...batch,
        index: existingIndexes[batch.id] || index,
        treeview: {
          quantity: 1,
          ...batch.treeview,
          childrenIds: children.map(v => v.treeview?.id || v.id),
        },
      },
      ...children,
    ]

    const existBatchIndex = batches.findIndex(b => b.id === batch.id && b.treeview?.level === batch.treeview?.level)
    const existBatch = existBatchIndex !== -1 ? batches[existBatchIndex] : null
    const existBatchWithChildrenIndexes = batches.filter(b => b.treeview?.id === existBatch?.treeview?.id || existBatch?.treeview?.childrenIds?.includes(b.treeview?.id)).map(b => b.index)
    const newBatchesWithChildrenIndexes = newBatches.map(b => b.index)

    if (existBatch && newBatchesWithChildrenIndexes.join("") === existBatchWithChildrenIndexes.join("")) {
      batches[existBatchIndex] = {
        ...existBatch,
        treeview: {
          ...existBatch.treeview,
          quantity: existBatch.treeview.quantity + 1,
        },
      }
      continue
    }

    batches = [
      ...batches,
      ...newBatches
    ]

    index = Object.keys(existingIndexes).length + 1
  }
  return { children: batches, indexes: existingIndexes }
}