import { v4 as uuidv4 } from "uuid"

export const BATCH_TABLE_COLUMNS = [
  "index",
  "filename",
  "name",
  "preview",
  "quantity",
  "material",
  "thickness",
  "process",
  "operations",
  "reference",
  "certificates",
  "boolean__is_valid",
  "price",
  "total",
  "actions",
]

export const DUMMY_BATCH = {
  certificates: [],
  created: "",
  created_by: null,
  exports: [],
  id: uuidv4(),
  is_valid: true,
  machines: [],
  options: {
    machines: [],
    stock: [],
    valid_stock: [],
  },
  order: null,
  parent: null,
  part: null,
  quantity: 0,
  stock: null,
  times: [],
  total_amount: 0,
  unit_amount: 0,
  updated: "",
  updated_by: null,
}