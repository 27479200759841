export function hideBatchTreeviewId(treeviewId: number, batches: any[], hiddenIds: number[] = []) {
  const batch = batches.find(b => b.treeview?.id === treeviewId)
  if (batch?.treeview?.childrenIds.length > 0) {
    for (let i = 0; i < batch.treeview.childrenIds.length; i++) {
      const childId = batch.treeview.childrenIds[i]
      hideBatchTreeviewId(childId, batches, hiddenIds)
    }
  }
  hiddenIds.push(treeviewId)
  return hiddenIds
}

export function handleBatchTree(batches: any[], hiddenIds: number[]) {
  for (let i = 0; i < batches.length; i++) {
    const batch = batches[i]
    const rowElement = document.querySelector(`tr[treeview-id='${batch.treeview?.id}']`)
    if (!rowElement) continue
    rowElement.classList.remove("d-none")
    if (batch.treeview.parent && hiddenIds.includes(batch.treeview.parent)) {
      rowElement.classList.add("d-none")
    }
  }
}

export function calculateTotalVisibleChildren(batches: any[], totalVisibleChildren: any) {
  const hasChildrenBatches = batches.filter(b => b.treeview?.childrenIds?.length > 0)
  for (let i = 0; i < hasChildrenBatches.length; i++) {
    const batch = hasChildrenBatches[i]
    const queryString = batch.treeview?.childrenIds?.map(id => `tr[treeview-id='${id}']:not(.d-none)`).join(",")
    totalVisibleChildren[batch.treeview?.id || batch.id] = (document.querySelectorAll(queryString) || []).length
  }
  return totalVisibleChildren
}